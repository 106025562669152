<template>
  <div class="dashboard__container" v-if="user">
    <Loader v-if="performingRequest" />
    <div class="dashboard__container--body">
      <div class="dashboard__container--body--col" style="width: 100%;">
        <div style="overflow: auto; padding:0;">
          <vue-good-table
            :columns="columns"
            :rows="userTimesheets"
            styleClass="vgt-table bordered condensed"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 25,
            }"
            :sort-options="{
              enabled: true,
              multipleColumns: true,
              initialSortBy: [
                {field: 'date', type: 'desc'},
                {field: 'firstName', type: 'asc'},
              ],
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'date'">
                <span v-if="props.row.date">{{props.row.date}}</span>
              </span>
              <span v-else-if="props.column.field == 'firstName'">
                <span v-if="props.row.firstName">{{props.row.firstName}}</span>
              </span>
              <span v-else-if="props.column.field == 'lastName'">
                <span v-if="props.row.lastName">{{props.row.lastName}}</span>
              </span>
              <span v-if="props.column.field == 'eventName'">
                <span v-if="props.row.shift.title">{{props.row.shift.title}}</span>
                <router-link :to="`/orders/` + props.row.eventId + `/timesheets`">
                  <i class="fas fa-external-link ml-3 mr-3"></i>
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'position'">
                <span v-if="props.row.position">{{props.row.position}}</span>
              </span>
              <span v-else-if="props.column.field == 'status'">
                <span v-if="props.row.status">{{props.row.status}}</span>
              </span>
              <span v-else-if="props.column.field == 'regRate'">
                <span v-if="props.row.regRate">${{props.row.regRate}}/hr</span>
              </span>
              <span v-else-if="props.column.field == 'dayRate'">
                <span v-if="props.row.dayRate">${{props.row.dayRate}}</span>
              </span>
              <span v-else-if="props.column.field == 'inTime'">
                <span v-if="props.row.inTime">{{formatAMPM(props.row.inTime)}}</span>
              </span>
              <span v-else-if="props.column.field == 'outTime'">
                <span v-if="props.row.outTime">{{formatAMPM(props.row.outTime)}}</span>
              </span>

              <span v-else-if="props.column.field == 'totalHours'">
                <span v-if="props.row.totalHours">{{props.row.totalHours}}</span>
              </span>
              <span v-else-if="props.column.field == 'breakTime'">
                <span v-if="props.row.breakTime">{{props.row.breakTime}}</span>
              </span>
              <span v-else-if="props.column.field == 'regHours'">
                <span v-if="props.row.regHours">{{props.row.regHours}}</span>
              </span>
              <span v-else-if="props.column.field == 'minHours'">
                <span v-if="props.row.minHours">{{props.row.minHours}}</span>
              </span>
              <span v-else-if="props.column.field == 'otHours'">
                <span v-if="props.row.otHours">{{props.row.otHours}}</span>
              </span>
              <span v-else-if="props.column.field == 'ot2Hours'">
                <span v-if="props.row.ot2Hours">{{props.row.ot2Hours}}</span>
              </span>
              <span v-else-if="props.column.field == 'mbp'">
                <span v-if="props.row.mbp">{{props.row.mbp}}</span>
              </span>
              <span v-else-if="props.column.field == 'tips'">
                <span v-if="props.row.tips">${{props.row.tips}}</span>
              </span>
              <span v-else-if="props.column.field == 'bonus'">
                <span v-if="props.row.bonus">${{props.row.bonus}}</span>
              </span>
              
              <span v-else-if="props.column.field == 'payTotal'">
                <span v-if="props.row.payTotal">${{props.row.payTotal}}</span>
              </span>
              <span v-else-if="props.column.field == 'paystatus'">
                <span v-if="props.row.paystatus">{{props.row.paystatus}}</span>
              </span>
              

            </template>
          </vue-good-table>
        </div>

        <div v-if="employeeTimesheetHistory && employeeTimesheetHistory.length >= 1" class="mt-5" 
          style="overflow: auto;">
          <vue-good-table
            :columns="columns2"
            :rows="employeeTimesheetHistory"
            styleClass="vgt-table bordered condensed"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 25,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'date'">
                <span v-if="props.row.date">{{props.row.date}}</span>
              </span>
              <span v-else-if="props.column.field == 'firstName'">
                <span v-if="props.row.firstName">{{props.row.firstName}}</span>
              </span>
              <span v-else-if="props.column.field == 'shiftEndAt.effectivePunchAt'">
                <span v-if="props.row.shiftEndAt && props.row.shiftEndAt.effectivePunchAt">{{props.row.shiftEndAt.effectivePunchAt | moment("MM/DD/YYYY hh:mm A")}}</span>
              </span>
              <span v-else-if="props.column.field == 'shiftStartAt.effectivePunchAt'">
                <span v-if="props.row.shiftStartAt && props.row.shiftStartAt.effectivePunchAt">{{props.row.shiftStartAt.effectivePunchAt | moment("MM/DD/YYYY hh:mm A")}}</span>
              </span>
              <span v-else-if="props.column.field == 'delete'">
                <button class="btn btn__small btn__dark" v-if="!props.row.payableDetails.paid" @click.once="deleteRow(props.row, props.index)">Delete
                </button>
                 <button v-if="props.row.payableDetails && props.row.payableDetails.paid" class="btn btn__outlined btn__small ml-2 mr-2">
                  Paid<i class="fa-solid fa-check ml-2"></i>

                  </button>
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import firebase from 'firebase/app';

export default {
  props: ['user'],
  name: 'userTimesheets',
  data: () => ({
    performingRequest: false,
    columns2: [
      {
        label: 'shift Id',
        field: 'workedShiftId',
      },
      {
        label: 'full Name',
        field: 'worker.fullName',
      },
      {
        label: 'Start',
        field: 'shiftStartAt.effectivePunchAt',
      },
      {
        label: 'End',
        field: 'shiftEndAt.effectivePunchAt',
      },
      {
        label: 'Pay Rate',
        field: 'effectivePayRate.amount',
      },
      {
        label: 'totalPayableAmount',
        field: 'totalPayableAmount.amount',
      },
      {
        label: 'Paid',
        field: 'payableDetails.paid',
      },
      {
        label: 'shiftDurations',
        field: 'shiftDurations.regularTimeWorked.totalDuration',
      },
      {
        label: 'shiftDurations',
        field: 'shiftDurations.overtimeWorked.totalDuration',
      },
      {
        label: 'shiftDurations',
        field: 'shiftDurations.doubleTimeWorked.totalDuration',
      },
      {
        label: '',
        field: 'delete'
      }
    ],
    columns: [
      {
        label: 'Date',
        field: 'date',
        width: '88px',
        sortable: false,
      },
      {
        label: 'First',
        field: 'firstName',
        width: '100px',
        sortable: false,
      },
      {
        label: 'Last',
        field: 'lastName',
        width: '100px',
        sortable: false,
      },
      {
        label: 'Job',
        field: 'eventName',
        width: '100px',
        sortable: false,
      },
      {
        label: 'Position',
        field: 'position',
        width: '152px',
        sortable: false,
      },
      {
        label: 'Shift Status',
        field: 'status',
        width: '152px',
        sortable: false,
      },
      {
        label: 'Base Rate',
        field: 'regRate',
        width: '96px',
        sortable: false,
      },
      {
        label: 'Time In',
        field: 'inTime',
        width: '100px',
        sortable: false,
      },
      {
        label: 'Time Out',
        field: 'outTime',
        width: '100px',
        sortable: false,
      },
      {
        label: 'Total Hours',
        field: 'totalHours',
        width: '88px',
        sortable: false,
      },
      {
        label: 'Break Time',
        field: 'breakTime',
        width: '88px',
        sortable: false,
      },
      {
        label: 'Reg Hours',
        field: 'regHours',
        width: '88px',
        sortable: false,
      },
      {
        label: 'Min Hours',
        field: 'minHours',
        width: '88px',
        sortable: false,
      },
      {
        label: 'OT',
        field: 'otHours',
        width: '88px',
        sortable: false,
      },
      {
        label: '2OT',
        field: 'ot2Hours',
        width: '88px',
        sortable: false,
      },
      {
        label: 'MBP Penalty',
        field: 'mbp',
        width: '88px',
        sortable: false,
      },
      {
        label: 'Tips',
        field: 'tips',
        width: '88px',
        sortable: false,
      },
      {
        label: 'Bonus',
        field: 'dayRate',
        width: '88px',
        sortable: false,
      },
      {
        label: 'Gross Earnings',
        field: 'payTotal',
        width: '126px',
        sortable: false,
      },
      {
        label: 'Pay Status',
        field: 'paystatus',
        width: '88px',
        sortable: false,
      },
    ],
  }),
  
  computed: {
    ...mapState(['currentUser', 'userProfile', 'userTimesheets', 'employeeTimesheetHistory']),
  },
  components: {
    Loader,
  },
  mounted () {
    this.$store.dispatch('getUserTimesheets', this.$route.params.id)
    this.$store.dispatch('retrieveEmployeeTimesheetHistory', this.$route.params.id)
  },
  methods: {
    async deleteRow(row, index) {
      this.performingRequest = true
      
      const deleteTimesheetFromEveree = firebase.functions().httpsCallable('deleteTimesheetFromEveree')
        await deleteTimesheetFromEveree({
        id: row.workedShiftId
      })
      .then(result => {
        console.log(result)
        this.$store.dispatch('retrieveEmployeeTimesheetHistory', this.user.id)
        
        if (result && (result == "200" || result == "204")) {
          console.log(result)
          this.employeeTimesheetHistory.splice(index, 1);
          
          
        } else {

        }
      })
      this.performingRequest = false
    },
    formatAMPM(date) {
      if (typeof date === "string") {
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        return `${hours}:${minutes} ${ampm}`;

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;

        return strTime;
      }

      return date;
    },
  },
  beforeDestroy() {
    this.$store.dispatch('clearUserTimesheets')
  }
}

</script>